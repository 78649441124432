ul.labels {
  list-style: none;
  padding: 0;
  margin: 0 0 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > li {
    font-size: 0.8em;
    padding: 0 8px;
    margin-right: 8px;
    margin-bottom: 2px;
    background-color: var(--color-orange);
  }
}

.result {
  font-size: 1.2em;
  padding: 3px;
  cursor: default;
}

.result.disabled {
  opacity: var(--opacity-disabled);
  cursor: pointer;
}
