.editor > div:last-of-type {
  border: 1px var(--color-medium-grey) solid;
  background-color: var(--color-offwhite);
  margin-top: var(--margin-between);
  padding: 12px;
  min-height: 100px;
}

.readOnly {
  & div .preview {
    background-color: var(--color-grey);
    border-left: none;

    & button {
      opacity: 1;

      & span {
        color: var(--color-white);
      }
    }
  }

  & > div:last-of-type {
    border: none;
    background-color: var(--color-white);
    cursor: not-allowed;

    & a {
      opacity: 1;
    }

    & a:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.styleButtons {
  border: 1px var(--color-grey) solid;
  border-radius: 5px;
  width: max-content;
  display: flex;
  margin: auto;

  & :last-child, :nth-of-type(5) {
    border-right: none;
  }

  & button {
    outline: none;
    padding-top: 5px;
    width: 30px;
    -webkit-appearance: none;
    border: none;
    border-right: 1px var(--color-grey) solid;
    background: transparent;
  }

  & button > svg {
    height: 16px;
    width: 16px;
  }
}

.linkButtons {
  display: flex;
  justify-content: space-around;
}

.preview {
  padding-top: 3px;
  border-left: 3px var(--color-grey) dotted;

  & button {
    width: max-content;
  }
}

.link {
  color: var(--color-blue);
  opacity: 0.6;

  & a:hover {
    cursor: text;
  }
}

.linkInput {
  margin-bottom: var(--margin-between);
  width: calc(100% - 22px) !important;
}

.confirmLink, .removeLink {
  width: 50%;
  margin-bottom: 15px;
}

.removeLink {
  min-height: 0;
  max-height: unset;
}

.hidden {
  display: none;
}
