.content {
  height: calc(100% - var(--height-workspace));
  background-color: var(--color-light-grey);
}

.newNote {
  background-color: var(--color-white);
  padding: var(--padding-container);
  padding-bottom: var(--padding-content);
  border-bottom: 1px solid var(--color-medium-grey);
}

.notesContainer {
  padding: var(--padding-container);
  overflow-y: auto;
  height: calc(100% - 220px);
}

.noteContainer {
  margin-top: var(--margin-between);
}

.note {
  min-height: 135px;
  overflow-y: auto;
  padding: var(--padding-medium);
  margin-top: var(--margin-small);
  background-color: var(--color-white);
  border: 1px solid var(--color-medium-grey);
  color: var(--color-dark-grey);
  font-size: var(--font-regular);

  > div {
    height: 100%;
  }
}

.newNoteFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--margin-between);
}

.pinnedField {
  cursor: pointer;
}

.noteHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--margin-between-topBottom);
}

.pinned {
  font-size: var(--font-small);
}

.validated {
  color: var(--color-green);
}

.requires-validation {
  color: var(--color-red);
}

.dateAndAuthor {
  font-size: var(--font-small);
  opacity: var(--opacity-disabled);
}

.author {
  font-style: italic;
}

.submit {
  width: 100px;
}

.error {
  margin-top: var(--margin-between);
  color: var(--color-error);
}

textarea {
  resize: none;
  border: 0;
}
