.sidebar > div:nth-child(2) > div:first-of-type {
  padding: 0;

  @media only screen and (max-width: 800px) {
    min-width: 200px;
    max-width: 200px;
  }

  @media only screen and (max-width: 650px) {
    min-width: 125px;
    max-width: 125px;
    word-break: break-word;
    hyphens: auto;
  }
}

.formContainer {
  width: calc(100% - 300px);
  padding: var(--padding-container);
  padding-right: 0;
  margin-right: var(--margin-between);
  overflow-x: scroll;

  @media only screen and (max-width: 800px) {
    width: calc(100% - 200px);
  }

  @media only screen and (max-width: 650px) {
    width: calc(100% - 125px);
  }
}
