.button {
  position: relative;
  width: 100%;
  /* Overrides for collapsing children in flex parents */
  min-height: var(--height-content);
  max-height: var(--height-content);
  padding: var(--padding-container) 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-white);
  text-align: center;
  border-radius: 0px;
  border: none;
  line-height: 1.12;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.2s all;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }

  * {
    vertical-align: middle;
  }
}

.buttonLabel {
  position: relative;
  z-index: 1;
  word-break: break-word;
  white-space: pre-wrap;
}

/* props.size */
.primary {
  max-width: 228px;
}

.secondary {
  max-width: 170px;
}

.auto {
  max-width: auto;
  width: auto;
  padding: var(--padding-container);
}

/* props.context */
.go {
  min-height: 0;
  max-height: unset;
  background-color: var(--color-action);
  &:hover {
    opacity: var(--opacity-hover);
  }
}

.review {
  background-color: var(--color-yellow);
  color: var(--color-dark-grey);
  &:hover {
    opacity: var(--opacity-hover);
  }
}

.stop {
  background-color: var(--color-destroy);
  &:hover {
    opacity: var(--opacity-hover);
  }
}

.new {
  display: flex;
  max-height: fit-content;
  justify-content: space-between;
  background-color: var(--color-action);
  padding: var(--padding-container) var(--padding-medium);

  &:hover {
    opacity: var(--opacity-hover);
  }

  & * {
    align-self: center;
  }

  & span {
    text-align: start;
  }
}

.quick-start,
.upload {
  background-color: var(--color-white);
  color: var(--color-light-navy);
  border: 2px solid var(--color-light-navy);

  &:hover {
    opacity: var(--opacity-hover);
  }
}

.progress,
.open {
  background-color: var(--color-medium-navy);
  &:hover {
    opacity: var(--opacity-hover);
  }
  &:hover .progressBar {
    opacity: var(--opacity-hover);
  }
}

.workspace {
  background-color: var(--color-light-navy);
  &:hover {
    opacity: var(--opacity-hover);
  }
}

.progressBar {
  background-color: var(--color-light-navy);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: 0.2s all;
}

.progressCount {
  position: absolute;
  right: var(--padding-container);
  top: 50%;
  transform: translateY(-50%);
}

/* props.disabled */
.disabled {
  opacity: var(--opacity-disabled);
  cursor: not-allowed;
}

.icon {
  height: var(--height-small);
  position: absolute;
  right: var(--padding-container);
  top: 50%;
  transform: translateY(-50%);
}

/* OVERRIDE - Avoid added pseudo content from ReactTable filters */
.button span:before,
.button span:after {
  content: none !important;
}
