.typeCellContainer {
  display: flex;
  padding: var(--padding-content) 0;
  grid-area: type;
}

.typeCellContainer div:first-of-type {
  margin-right: var(--margin-around);
}

.typeLink {
  font-size: var(--font-big);
  text-transform: capitalize;
  text-decoration: none;
  color: var(--color-light-navy);
  transition: 0.2s all;
}

.typeLink:hover {
  color: var(--color-dark-navy);
  opacity: var(--opacity-hover);
}
