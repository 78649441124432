$height-content: 63px;

.container {
  height: 90px;
  min-height: max-content;
  padding: var(--padding-container);
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  & a {
    color: var(--color-light-navy);
  }
  & > div {
    padding-right: var(--padding-container)
  }
  & > div:last-of-type {
    padding: 0;
  }
}

.home {
  display: flex;
  flex-wrap: nowrap;
  & > div {
    margin: auto;
  }
}

.newVersion {
  font-size: var(--font-small);
  padding: 0 var(--padding-container);
  margin: auto 0;
  margin-left: var(--margin-between);
  min-height: 40px;
  bottom: 2px;
}

.locationDate {
  font-size: 18px;
  height: $height-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  white-space: nowrap;
}

.additionalInfo {
  height: $height-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  width: 50px;
  height: 50px;
}

.chat {
  word-break: break-all;
}

@media only screen and (max-width: 800px) {
  .container {
    height: 120px;
    flex-wrap: wrap;
  }

  .home {
    flex-basis: calc(200px - var(--padding-container));
  }

  .locationDate {
    font-size: var(--font-regular);
  }

  .additionalInfo {
    flex-direction: row;
    flex-basis: calc(800px - 2*var(--padding-container));
    height: max-content;
    margin-top: var(--margin-around);
  }
}

@media only screen and (max-width: 650px) {
  .home {
    flex-basis: 135px;
  }
}

@media only screen and (max-width: 530px) {
  .container {
    height: 190px;
  }
}
