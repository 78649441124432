.contextMenu {
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-items: center;
  position: absolute;
  background: white;
  box-shadow: 2px 2px 5px var(--color-grey);
  width: fit-content;
  padding: 0 !important;

  & > button {
    height: 25px;
    border: none;
    border-bottom: 1px solid var(--color-medium-grey);
    outline: none;

    &:hover {
      cursor: pointer;
      background: var(--color-bluey-grey);
      opacity: var(--opacity-hover);
    }

    &:active {
      opacity: 1;
    }
  }
}

.hidden {
  visibility: hidden;
}
