.form {
  overflow-y: scroll;
  height: calc(100% - 2*var(--height-workspace));
}

.avalancheSingle {
  min-height: 0 !important;
  position: relative;

  @media only screen and (max-width: 1200px) {
    display: block !important;

    & > * {
      width: 100% !important;
    }

    & > div:last-of-type {
      width: fit-content !important;
    }
  }
}
