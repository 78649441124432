.button {
  height: var(--height-workspace);
  width: 50px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  position: relative;
  color: var(--color-white);
}

.button:focus {
  outline: none;
}

.headerButton > svg {
  fill: var(--color-white);
}

.close > svg {
  width: 18px;
  fill: var(--color-dark-navy);
}

.messageButton {
  background-color: var(--color-dark-navy);
  width: var(--height-button);
  color: var(--color-white);
}

.buttonActive {
  background-color: var(--color-white);
}

.disabled {
  opacity: var(--opacity-disabled);
}

.disabled:hover {
  cursor: not-allowed;
}
