.container {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  margin-right: var(--margin-between);
}

.container:last-of-type {
  margin-right: 0;
}

.workspace {
  width: 100%;
  height: 100%;
  min-width: 325px;
  background-color: var(--color-white);
  border: 1px solid var(--color-medium-grey);
  overflow-x: hidden;
}
