.link {
  font-size: var(--font-regular);
  display: inline-block;
  color: var(--color-light-navy);
  text-decoration: underline;
  transition: 0.2s all;
}

.link:hover {
  opacity: var(--opacity-hover);
}

.noUnderline {
  text-decoration: none;
}
