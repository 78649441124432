.textarea {
  background-color: var(--color-white);
  border-radius: 2px;
  width: 100%;
  padding: var(--padding-medium);
  font-size: var(--font-regular);
  font-weight: 300;
  transition: all 0.4s;
  border: 1px solid var(--color-medium-grey);
  min-height: 300px;

  &:focus {
    outline-width: 0;
  }

  &[disabled] {
    background-color: var(--color-grey);
  }
}

:global(.inputHasError) .textarea,
.globalError {
  border-color: var(--color-error);
}

.small {
  min-height: 130px;
}
