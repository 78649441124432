.problem {
  height: 100%;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--color-light-navy);
}

.problem:last-child {
  border-bottom: 0;
}

.typeAndKeys {
  display: flex;
  justify-content: space-between;
}

.keys {
  display: flex;
  padding-top: 5px;
}

.deleteContainer {
  height: initial;
  width: initial;
}

.aspectAndHazard {
  display: flex;
  justify-content: flex-start;
}

.aspectElevation {
  width: 100px;
  padding: 10px 0;

  * {
    cursor: default;
  }
}

.hazard {
  width: 150px;
  margin: 0 auto;
}
