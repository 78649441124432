$width-datepicker: 92px;
$top-sortArrow: 33px;

$zindex-headers: 10;
$zindex-filters: 5;
$zindex-date: 3;

/* ReactTable */
:global .ReactTable {
  border: none;
  height: 100vh;
  min-width: fit-content;
  font-size: var(--font-regular);
}

:global .ReactTable .rt-table {
  overflow: hidden;
}

:global .ReactTable .rt-tr-group {
  border-bottom: 0 !important;
}

/* Head & Body */
:global .ReactTable .rt-thead,
:global .ReactTable .rt-tbody {
  min-width: 0 !important;
}

:global .ReactTable .rt-thead .rt-tr {
  text-align: left;
}

:global .ReactTable .rt-thead.-headerGroups,
:global .ReactTable .rt-thead.-header {
  background-color: var(--color-dark-navy);
  color: var(--color-white);
  font-weight: 600;
}

:global .ReactTable .rt-thead.-headerGroups {
  height: 0;
}

:global .ReactTable .rt-thead.-header,
:global .ReactTable .rt-thead.-filters {
  height: var(--height-workspace);
}

:global .ReactTable .rt-thead.-filters {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  z-index: $zindex-filters;
  height: var(--height-tall);
  margin-bottom: var(--margin-small);
  overflow-x: scroll;
}

:global .ReactTable .rt-thead.-filters select {
  padding: 5px var(--padding-container);
  padding-right: var(--padding-content);
}

:global .ReactTable .rt-thead.-filters,
:global .ReactTable .rt-thead.-filters .rt-th {
  overflow: visible;
}

:global .ReactTable .rt-thead .rt-th.-sort-asc,
:global .ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none !important;
}

:global .ReactTable .rt-thead .rt-th {
  position: relative;
}

:global .ReactTable .rt-thead .rt-th .rt-resizable-header-content {
  padding-top: var(--padding-medium);
}

/* Time Last Saved */
:global .ReactTable .rt-thead .rt-resizable-header:last-child {
  > div {
    padding-top: 0;
    height: 100%;
    display: flex;
  }

  > div div {
    white-space: pre-wrap;
    align-self: center;
    margin-bottom: 0;
    color: var(--color-white);
  }
}

:global .ReactTable .rt-thead .rt-resizable-header::focus {
  outline: none !important;
}

:global .ReactTable .rt-thead .rt-th.-sort-asc .rt-resizable-header-content span::before {
  content: "↑";
}

:global .ReactTable .rt-thead .rt-th.-sort-desc .rt-resizable-header-content span::before {
  content: "↓";
}

:global .ReactTable .rt-thead .rt-th.-sort-asc .rt-resizable-header-content span::before,
:global .ReactTable .rt-thead .rt-th.-sort-desc .rt-resizable-header-content span::before {
  position: absolute;
  right: 5px;
  top: $top-sortArrow;
  transform: translateY(-50%);
}

:global .ReactTable .rt-thead.-header {
  box-shadow: none;
}

:global .ReactTable .rt-thead.-header :local(.newProductHeader):global.rt-th,
:global .ReactTable .rt-thead.-header :local(.quickStartAndReviewHeader):global.rt-th {
  position: relative;
}

:global .ReactTable .rt-tbody .rt-td {
  background-color: var(--color-light-grey);
  border-right: none;
  &:first-of-type {
    background-color: var(--color-bluey-grey);
  }
}

:global .ReactTable .rt-td {
  display: flex;
  align-items: center;
  white-space: unset;
  text-overflow: unset;
  border-bottom: solid 1px rgba(0,0,0,0.05);
}

:global .ReactTable .rt-th {
  position: relative;
}

:global .ReactTable .rt-th + .publishDate input,
:global .ReactTable .rt-td + .publishDate input {
  width: 100%;
  text-align: center;
}

:global .ReactTable .rt-th,
:global .ReactTable .rt-td {
  &:local + .publishDate {
    overflow: visible;
    z-index: 1;
    input {
      height: var(--height-content);
      padding: var(--padding-container);
    }
  }

  &:local + .publishDateHeader {
    overflow: visible;
    z-index: 1;
    input {
      height: var(--height-content);
      padding: var(--padding-container);
    }
  }
}

:global .ReactTable .publishDate {
  overflow: visible;

  & > div {
    width: 100%;
  }
}

:global .DayPickerInput-OverlayWrapper {
  position: relative;
  z-index: $zindex-date;
}

:global .ReactTable input {
  height: var(--height-content);
}

/* Footer */
:global .ReactTable .pagination-bottom .-pagination {
  padding: var(--padding-container);
}

:global .ReactTable .pagination-bottom .-pagination .-previous,
:global .ReactTable .pagination-bottom .-pagination .-next {
  flex: none;
}

:global .ReactTable .pagination-bottom .-pagination .-previous button,
:global .ReactTable .pagination-bottom .-pagination .-next button {
  width: 170px;
  height: var(--height-content);
  border: 2px solid var(--color-light-navy);
  border-radius: 0;
  background-color: var(--color-white);
  color: var(--color-light-navy);
  transition: 0.2s all;
}

:global .ReactTable .pagination-bottom .-pagination .-previous button:hover:not([disabled]),
:global .ReactTable .pagination-bottom .-pagination .-next button:hover:not([disabled]) {
  background-color: var(--color-white);
  opacity: 0.2;
}

:global .ReactTable .-pagination .-center {
  max-width: 350px;
}

:global .ReactTable .-pagination .-center input,
:global .ReactTable .-pagination .-center select {
  height: var(--height-content);
  -webkit-appearance: none;
  -moz-appearance: none;
}

:global .ReactTable .-pagination .-center input {
  width: var(--font-regular)40px;
  text-align: center;
}

:global .ReactTable .-pagination .-center input[type='number']::-webkit-inner-spin-button,
:global .ReactTable .-pagination .-center input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:global .ReactTable .-pagination .-center select {
  width: 100px;
}

:global .ReactTable .-pagination .-center .select-wrap {
  position: relative;
}

:global .ReactTable .-pagination .-center .-pageInfo,
:global .ReactTable .-pagination .-center .select-wrap {
  margin: 0;
}

:global .ReactTable .-pagination .-center .select-wrap:after {
  content: '';
  position: absolute;
  top: 16px;
  right: 10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--color-grey);
}
