.count {
  position: relative;
  background-color: var(--color-yellow);
  height: var(--width-buttons);
  width: var(--width-buttons);
  color: var(--color-dark-grey);
}

.hide {
  background-color: transparent;
}

.count span {
  display: inline-block;
  padding-top: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.priority-low {
  background-color: var(--color-green);
}

.priority-moderate {
  background-color: var(--color-yellow);
}

.priority-high {
  background-color: var(--color-red);
  color: var(--color-white);
  opacit: var(--opacity-hover);
}
