.infoTip {
  display: inline;
  position: absolute;
  margin-left: 2px;

  > span > svg {
    margin-top: -5px;
    position: relative;
    top: 3px;
  }
}

.label {
  margin-left: 5px;
  font-size: 10px;
}

.infoTable {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--color-offwhite);
  border: 1px solid var(--color-grey);
  font-size: 13px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);

  > thead > tr {
    display: flex;

    > th {
      flex: 1;
      position: sticky;
      top: 0;
      font-weight: bold;
      font-size: 14px;
      background-color: lightgrey;
      text-align: center;
      justify-content: center;
      padding: 8px;
      border-left: 1px solid var(--color-grey);
      border-bottom: 1px solid var(--color-grey);
      display: flex;
      align-items: center;
    }

    > th:first-of-type {
      border-left: 0;
    }
  }

  > tbody {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    > tr {
      flex: 1;
      display: flex;
      border-bottom: 1px solid var(--color-grey);

      > th, td {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 8px;
      }

      > th {
        font-weight: bold;
        font-size: 14px;
        justify-content: center;
      }

      > td {
        border-left: 1px solid var(--color-grey);
      }
    }

    > tr:last-of-type {
      border-bottom: 0;
    }
  }
}

.resize {
  z-index: 99;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  fill: var(--color-black);
}
