$height-content--withHeader: calc(100% - var(--height-workspace));
$height-content--withHeaderAndFooter: calc(100% - 2*var(--height-workspace));


.content {
  width: 100%;
  overflow-y: scroll;
  height: $height-content--withHeader;
}

.hasFooter {
  height: $height-content--withHeaderAndFooter;
}

.hasMainAndAside {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.noScroll {
  overflow-y: auto;
}
