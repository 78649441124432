/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 12px;
}

* {
  -webkit-font-smoothing: antialiased;
}


table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset {
  border: none;
}
