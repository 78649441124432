$padding-dateTime: 5px 8px;
$border-dateTime: 1px solid var(--color-medium-grey);

.noPadding {
  padding: 0;
}

.textCell:not(.noColor) {
  color: var(--color-dark-grey);
}

.time {
  white-space: nowrap;
  width: 100%;
}

.date,
.hour,
.min {
  display: inline-block;
  vertical-align: middle;
  padding: $padding-dateTime;
  height: var(--height-content);
  border: $border-dateTime;
  padding-top: 12px;
  margin-right: var(--margin-between);
  color: var(--color-dark-grey);
  cursor: default;
  width: 100%;
}

.date {
  text-align: center;
  margin-bottom: var(--margin-small);
}

.hour,
.min {
  width: calc(50% - 5px);
  margin: 0;
}

.colon {
  display: inline-block;
  width: 10px;
  text-align: center;
}
