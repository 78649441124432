.button {
  flex-grow: 1;
  color: var(--color-white);
  min-height: var(--height-content);
  max-height: var(--height-content);
  min-width: 100px;
  font-size: var(--font-regular);
  border: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }
}

.selected {
  background-color: var(--color-light-navy);
  border: solid;
  border-color: var(--color-white);
  border-width: 0 1px;
}

.unselected {
  background-color: var(--color-light-medium-grey);
  color: var(--color-black);
}
