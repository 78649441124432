.timePickerGrid {
  justify-content: space-between !important;
}

.hour {
  padding-right: 2px;
  position: relative;
}

.min {
  padding-left: 5px;
}

.hour:after {
  content: ':';
  position: absolute;
  right: -5px;
  top: 0;
  transform: translateY(-50%);
  top: 50%;
}
