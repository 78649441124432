/* index.js */
.page {
  display: grid;
  grid-template-columns: 100vw;
}

.content {
  grid-row-start: 2;
  grid-row-end: 2;
}

.center {
  justify-content: center;
}

/* pageHeader.js */
.pageHeader {
  height: 60px;
  background-color: var(--color-dark-navy);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  /* calc(known left-margin of hamburger + width of hamburger + right-margin of hamburger ) */
  padding-left: calc(10px + 40px + 10px);
}

.loading {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
}
