$size-checkbox: 16px;

.checkBoxContainer {
  position: relative;
  cursor: pointer;
}

.labelCheckbox {
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
  display: inline-block;
}

.labelCheckbox input:checked + .checkbox {
  border-color: var(--color-grey);
}

.labelCheckbox input:checked + .checkbox svg path {
  fill: var(--color-light-navy);
  stroke: var(--color-light-navy);
  stroke-width: 2;
  stroke-dashoffset: 0;
}

.labelCheckbox input:checked + .checkbox svg polyline {
  stroke-dashoffset: 0;
}
.labelCheckbox input:hover + .checkbox svg path {
  stroke-dashoffset: 0;
}

/* Alter the size of the visible checkbox below */
.checkbox {
  position: relative;
  top: 0px;
  float: left;
  margin-right: var(--margin-between);
  width: $size-checkbox;
  height: $size-checkbox;
  border: 2px solid var(--color-grey);
  border-radius: 3px;
  box-sizing: border-box;
}

.checkbox svg polyline {
  fill: none;
  stroke: var(--color-white);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  transition: all 0.3s ease;
}
/* Alter the size of the visible checkbox above */

.checkbox svg {
  position: absolute;
  top: -2px;
  left: -2px;
  width: $size-checkbox;
  height: $size-checkbox;
}

.checkbox svg path {
  fill: none;
  stroke: var(--color-light-navy);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 71px;
  stroke-dashoffset: 71px;
  transition: all 0.6s ease;
}

.disabled svg path {
  stroke: none;
}

.checkbox span {
  pointer-events: none;
  vertical-align: middle;
  font-family: sans;
}

.checkBoxContainer {
  width: 200px;
  cursor: pointer;
}

.checkBoxContainer.disabled .labelCheckbox .checkbox {
  background: var(--color-grey);
}

input.invisible {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: $size-checkbox;
  height: $size-checkbox;
  opacity: 0;
  cursor: pointer;
  color: var(--color-light-navy);
}

.checkBoxContainer.noLabel {
  width: $size-checkbox;
  height: $size-checkbox;
  color: var(--color-light-navy);
}

input:-moz-read-only {
  cursor: pointer !important;
}

input:disabled {
  cursor: not-allowed !important;
}
