$height-950w: 143px;
$height-554w: 189px;
$height-406w: 225px;

.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: stretch;
  position: relative;
  z-index: 0;
  overflow-y: scroll;
}

/* props.sidebarAlignment */
.left {
  flex-direction: row;
}

.right {
  flex-direction: row-reverse;
}

.regular {
  height: calc(100vh - var(--height-workspace));
}

.tall {
  height: calc(100vh - var(--height-tall));

  @media only screen and (max-width: 950px) {
    height: calc(100vh - #{$height-950w});
  }

  @media only screen and (max-width: 554px) {
    height: calc(100vh - #{$height-554w});
  }

  @media only screen and (max-width: 406px) {
    height: calc(100vh - #{$height-406w});
  }
}

/* props.backgroundColor */
.white {
  background-color: var(--color-white);
}

.grey {
  background-color: var(--color-light-grey);
}
