.top {
  margin-top: 1em;
}
.right {
  margin-right: 1em;
}
.left {
  margin-left: 1em;
}
.bottom {
  margin-bottom: 1em;
}
.vertical {
  composes:top;
  composes:bottom;
}
.horizontal {
  composes:left;
  composes:right;
}
.all{
  composes:vertical;
  composes:horizontal;
}
