.locationHandleTitleCell {
  color: var(--color-light-navy);
  text-decoration: none;
}

.locationHandle {
  width: 238px;
}

.editLocation {
  text-align: right;
}

.buttonContainer {
  height: var(--height-content);

  a {
    text-decoration: none;
  }
}

.textCell {
  padding-top: var(--padding-medium);
  padding-bottom: 12px;
}
