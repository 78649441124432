$dot: 12px;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.itemContainer {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--color-medium-grey);
  flex-shrink: 0;

  @media only screen and (max-width: 650px) {
    padding-right: var(--padding-container);
  }
}

.item {
  color: var(--color-black);
  font-size: 17px;
  min-height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;

  @media only screen and (max-width: 800px) {
    font-size: var(--font-regular);
  }

  @media only screen and (max-width: 650px) {
    font-size: var(--font-small);
  }
}

.item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.2s all;
  z-index: -1;
}

.active:before {
  background-color: var(--color-white);
}

.active:after {
  display: none;
}

.labelContainer {
  height: 100%;
}

.check {
  margin: 0 15px 0 var(--margin-around);
}


/* This element creates a right border under the navigational elements */
.rightBorder {
  flex: 1;
  border-right: 1px solid var(--color-medium-grey);
}

/* This pseudo-element creates a border on inactive navigational elements */
.item:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: var(--color-medium-grey);
}

.notificationDot {
  position: absolute;
  width: $dot;
  height: $dot;
  background-color: var(--color-yellow);
  right: 15px;
  top: 18%;
  transform: translateY(-50%);
  border-radius: 50%;
}
