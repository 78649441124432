$width-aside: 370px;

.main {
  padding: var(--padding-content);
  width: 100%;
  min-width: 200px;
}

.hasSiblingAside {
  width: calc(100% - #{$width-aside});
}
