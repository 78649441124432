
.select {
  width: 100%;
  height: var(--height-content);
  background: var(--color-white);
  text-indent: 0;
  text-overflow: '';
  appearance: none;
  padding: 5px var(--padding-container);
  padding-right: var(--padding-content);
  color: var(--dark-grey);
  font-size: var(--font-regular);
  color: var(--color-dark-grey);
  border: solid 1px var(--color-medium-grey);
  border-radius: 0;
  cursor: pointer;
}

.selectContainer {
  position: relative;
  width: 100%;
  select:focus {
    width: 100%;
  }
}

.selectContainer:after {
  content: '';
  position: absolute;
  top: 17px;
  right: 10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--color-grey);
  pointer-events: none;
}

.disabled {
  background-color: var(--color-light-grey);
  color: var(--color-dark-grey);
  /* Safari ignores color on disabled inputs */
  -webkit-text-fill-color: var(--color-dark-grey);
  cursor: not-allowed;
}

.dangerRating {
  background-repeat: no-repeat;
  background-position: 0.25em center;
  background-size: contain;
  padding-left: 4.5em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}
