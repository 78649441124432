.container {
  margin-bottom: var(--margin-around);
  font-size: var(--font-regular);
}

.updatePassword {
  margin-bottom: var(--margin-around);
}

.linkSpoof {
  color: var(--color-light-navy);
  text-decoration: underline;
  cursor: pointer;
}

.instructions {
  font-style: italic;
  margin-top: var(--margin-between);
  color: var(--color-dark-grey);
}
