.inputWrapper {
  margin-bottom: 15px;
}

.inputWrapper input {
  transition: 0.2s all;
}

.errorText {
  color: var(--color-error);
  font-size: var(--font-small);
  margin-top: var(--margin-small);
}

.error input {
  border-bottom: 2px solid var(--color-error) !important;
}

.overLimit {
  color: var(--color-error) !important;
}
