.label {
  display: inline-block;
  margin-bottom: var(--margin-between);
  font-size: var(--font-small);
  font-weight: 400;
  position: relative;
}

.required:after {
  content: '*';
  padding-left: 3px;
  color: var(--color-light-navy);
}
