$zindex-container: 50;
$zindex-overlay: 50;
$zindex-menu: 60;
$zindex-dropdown: 70;

.container {
  z-index: $zindex-container;
  position: absolute;
  margin: var(--margin-between) 0 0 var(--margin-between);
}

.menu {
  height: var(--height-content);
  width: var(--height-content);
  z-index: $zindex-menu;
  cursor: pointer;
  background-color: var(--color-medium-navy);
  fill: var(--color-white);
}

.dropdown {
  position: absolute;
  display: block;
  top: var(--height-content);
  z-index: $zindex-dropdown;
  background-color: var(--color-white);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.navItem {
  display: block;
  padding: var(--padding-container);
  width: 200px;
  background-color: var(--color-white);
  color: var(--color-dark-grey);
  text-decoration: none;
  transition: 0.2s all;
  font-size: var(--font-regular);
}

.navItem:hover {
  background-color: var(--color-bluey-grey);
  color: var(--color-light-navy);
}

.navItem.active {
  color: var(--color-light-navy);
  opacity: var(--opacity-hover);
}

.invisibleOverlay {
  position: fixed;
  z-index: $zindex-overlay;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
