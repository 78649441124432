.ProgressBar {
  bottom: calc(0.75em + 3px);
  height: 5px;
  border: 1px solid transparent;
}

progress, progress[role] {
  appearance: none;
  border: none;
  background-size: auto;
  height: 100%;
  width: 100%;
}

progress::-webkit-progress-value {
  background: var(--color-blue);
}
