:global .DayPickerInput {
  width: 100%;
  input {
    width: 100%;
    height: var(--height-content);
    overflow: hidden;
    color: var(--color-dark-grey);
    &:global + .hide {
      &::after {
        content: '--'
      }
    }
  }
}

:global(.DayPickerInput) input {
  color: var(--color-dark-grey);
  font-size: var(--font-regular);
  text-align: center;
}

:global(.DayPickerInput) input:disabled {
  color: var(--color-dark-grey);
  /* Safari ignores color on disabled inputs */
  -webkit-text-fill-color: var(--color-dark-grey);
  background-color: var(--color-light-grey);
}
