.deleteButton {
  display: block;
  width: 12px;
  height: 12px;
  margin: var(--margin-between);
  margin-right: 0;
  cursor: pointer;
}

.disabledButton {
  cursor: default;
}

.disabledIcon {
  opacity: var(--opacity-disabled);
}

.icon {
  fill: var(--color-light-navy);
}
