.wrapper {
  display: flex;

  > div:nth-of-type(2) {
    position: unset;
    margin-top: 1px;
  }
}

.optionSet {
  display: flex;
  flex-wrap: wrap;
  margin-right: var(--margin-small);
  flex: 1;
}

.status--yes {
  background-color: var(--color-error);
}

.status--no {
  background-color: var(--color-green);
}

.status--unknown {
  background-color: var(--color-warning);
}

.confidenceInfoTable {
  > tbody > tr > th, thead > tr > th:first-of-type {
    flex: none !important;
    width: 200px;
  }
}
