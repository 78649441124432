.Item {
  position: relative;
  margin: 1em 0;
  border: 1px solid var(--color-medium-grey);

  &[open] summary {
    border-bottom: 1px solid var(--color-medium-grey);

    &:before {
      content: '▼';
    }
  }

  summary {
    padding: 1em;
    cursor: pointer;
    background-color: var(--color-light-grey);
    list-style: none;

    &:before {
      content: '▶';
      font-size: 0.75em;
      margin-right: 0.5em;
      color: var(--color-dark-grey);
    }

    &::-webkit-details-marker {
      display: none;
    }
  }
}


.Content {
  display: flex;
  align-items: stretch;

  > div {
    flex-grow: 1;
    margin: 1em 0 1em 1em;
    border: none;
    min-height: 100px;

    &:hover, &:focus {
      resize: vertical;
    }
  }

  & .deleteButton {
    padding: 1em;
    background-color: transparent;
    border: none;
    outline: none;
  }

  & button {
    opacity: var(--opacity-disabled);

    &:hover {
      opacity: 1;
    }
  }
}

.FloatRight {
  float: right;
}
