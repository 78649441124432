/* <DateAndTimePicker /> */
.container {
  display: inline-block;
}

.time > div > div {
  padding-left: 0;
}

.date,
.time {
  vertical-align: bottom;
  display: inline-block;
  width: 100%;
}

:local(.container) :global(.DayPickerInput) input {
  /* Not sure why we needed border-box restated :thinking_face: */
  box-sizing: border-box;
  width: 102px;
  height: var(--height-content);
  border: 1px solid var(--color-medium-grey);
  margin-bottom: 5px;
}
