.container {
  width: 100%;
  position: relative;
}

.left {
  display: inline-block;
  width: 45%;
  min-height: 300px;
  min-width: 300px;
  top: 0;
  left: 0;
}

.right {
  display: inline-block;
  width: 45%;
  font-size: var(--font-regular);
}

.set {
  margin-bottom: var(--margin-small);
}

.fullSize {
  /* Fighting .mapboxgl-map */
  position: absolute !important;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
