.container {
  max-width: 250px;
  max-height: 250px;
  position: relative;
}

.svg {
  width: 100%;
  height: 100%;
}

.aspect {
  cursor: pointer;
  transition: 0.2s all;
}

.elevation {
  cursor: pointer;
  transition: 0.2s all;
}

.checkboxWrapper {
  display: none;
}
