.moveKeys {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.disabled {
  opacity: var(--opacity-disabled);
  cursor: default;
}

.icon {
  fill: var(--color-light-navy);
}
