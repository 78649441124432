/*
 * Simply hide the "advisory" option from the product type dropdown 
 * This should not hang around for too long
 */
:global #HACK_TO_REMOVE_ADVISORY option[value="advisory"] {
  display: none;
}

.productTitleCell {
  text-decoration: none;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.container {
  display: flex;
  width: 100%;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.newProductButton:first-of-type {
  margin-right: var(--margin-between);
}

.newProductButton > button {
  width: 80px;
  height: var(--height-content);
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.carryforward {
  display: block;
  margin: var(--margin-between);
  cursor: pointer;
}

.carryforward > svg {
  fill: var(--color-light-navy);
  padding: 1px;
  margin-top: 1px;
}
