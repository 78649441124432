.header {
  background-color: var(--color-dark-navy);
  display: flex;
  justify-content: space-between;
  color: var(--color-white);
  font-size: 20px;
  height: var(--height-workspace);
}

.left {
  display: flex;
  justify-content: left;
}

.number {
  width: 60px;
  height: var(--height-workspace);
  background-color: var(--color-light-navy);
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0 var(--margin-between);
  display: flex;
  align-items: center;
  justify-content: left;
}

.lastTimeSaveContainer {
  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--color-white);
    margin: 0;
  }
}
