.container {
  position: relative;
  z-index: 1;
  padding: var(--padding-container);
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.white {
  background-color: var(--color-white);
}

.grey {
  background-color: var(--color-light-grey);
}

.noShadow {
  box-shadow: none;
}

.borderRight {
  border-right: 1px solid var(--color-medium-grey);
}

.narrow {
  /* Work-around for in order for width to be applied to flex-child */
  min-width: 300px;
  max-width: 300px;
}
