.form {
  height: max-content;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.avalancheProblemForm {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr 1.5fr;
  grid-template-rows: repeat(8, auto);
  grid-template-areas:
    "problem problem weakLayer aspectElevation"
    "distribution distribution sensitivity aspectElevation"
    "typicalSize typicalDepth typicalDepth aspectElevation"
    "comment comment comment aspectElevation"
    "comment comment comment hazard"
    "comment comment comment hazard"
    "comment comment comment hazard"
    "comment comment comment hazard";

  @media only screen and (max-width: 1320px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "problem  weakLayer"
      "distribution sensitivity"
      "typicalSize typicalDepth"
      "aspectElevation hazard"
      "aspectElevation hazard"
      "aspectElevation hazard"
      "comment comment"
      "comment comment";
  }

  @media only screen and (max-width: 690px) {
    grid-template-columns: 0.5fr 0.5fr 1fr;
    grid-template-areas:
      "problem problem weakLayer"
      "distribution distribution sensitivity"
      "typicalSize typicalDepth typicalDepth"
      "aspectElevation aspectElevation aspectElevation"
      "hazard hazard hazard"
      "comment comment comment"
      "comment comment comment"
      "comment comment comment";
  }

  grid-gap: 0 20px;
}

.problemType {
  grid-area: problem;

  > div {
    margin-bottom: 5px;
  }
}
.distribution {
  grid-area: distribution;

  > div {
    margin-bottom: 5px;
  }
}
.sensitivity {
  grid-area: sensitivity;

  > div {
    margin-bottom: 5px;
  }
}
.weakLayer {
  grid-area: weakLayer;
  align-self: end;

  > div {
    margin-bottom: 5px;
  }
}
.typicalSize {
  grid-area: typicalSize;

  > div {
    margin-bottom: 5px;
  }
}
.typicalDepth {
  grid-area: typicalDepth;
  align-self: end;

  > div {
    margin-bottom: 0px;
  }
}

.inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
  flex: 1;
}

.input {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  flex: 1;

  > div {
    min-width: 50px;
  }
}

.inline span {
  display: inline-block;
  padding: 13px 0;
  font-size: var(--font-small);
}

.inline > div > div {
  margin-bottom: 0;
  flex: 1;
}

.to {
  margin: var(--margin-between-rightLeft);
}

.unit {
  margin-left: var(--margin-small);
}

.aspectElevation {
  grid-area: aspectElevation;
  max-width: 500px;

  > div {
    padding-bottom: var(--padding-container);
    margin: 0 auto;
  }

  @media only screen and (max-width: 690px) {
    > div {
      max-height: 65%;
    }
  }
}

.hazardChartWrapper {
  position: relative;

  > label {
    margin-bottom: 0;
  }

  > svg {
    margin-top: -15px;
  }
}

.hazardChart {
  max-width: 500px;
  grid-area: hazard;
}

.hazardReset {
  display: inline-block;
  width: min-content;
  border-radius: 1px;
  position: absolute;
  right: 20px;
  top: -25px;

  &:focus {
    outline: none;
  }
}

.hazardInfo {
  display: flex;
  justify-content: space-between;

  > div:first-of-type {
    left: 10px
  }

  > div:nth-of-type(2) {
    right: 20px;
  }
}

.avalancheProblemComment {
  grid-area: comment;
  margin-bottom: var(--margin-around);

  & details {
    margin-top: 0;
  }

  @media only screen and (max-width: 690px) {
    margin-top: 25px;
  }
}

.errorText {
  color: var(--color-error);
  font-size: var(--font-small);
  margin: 0 !important;
  padding-top: 0 !important;
}
