label {}

label > input:not([type='checkbox']),
label > textarea,
label > select {
  margin-top: 0.75em;
}

label > input[type='checkbox'] {
  margin-right: 0.5em;
}

input {
  transition: all 0.5s;
}

input:not([type='checkbox']) {
  width: 100%;
  background-color: var(--color-white);
  color: var(--color-dark-grey);
  border: 1px solid var(--color-medium-grey);
  height: var(--height-content);
  padding: 0 var(--padding-container);
}

input:disabled,
textarea:disabled,
input:read-only,
textarea:read-only {
  background-color: var(--color-light-grey);
  /* Safari ignores color on disabled inputs */
  -webkit-text-fill-color: var(--color-dark-grey);
  cursor: not-allowed;
}

select:invalid,
input:invalid,
textarea:invalid {
  border-color: var(--color-error);
}

textarea {
  background-color: var(--color-white);
  width: 100%;
  padding: 1em;
  border-radius: 2px;
  color: var(--color-dark-grey);
  transition: all 0.4s;
  border: 1px solid var(--color-medium-grey);
}
