.visibleDays {
  display: flex;
  height: var(--height-content);
  width: calc(var(--width-buttons) * 4 + var(--margin-between) * 3);
  margin-top: var(--margin-between);
}

.visibleDayButton:last-child {
  margin-right: 0;
}

.visibleDayButton {
  height: var(--height-content);
  width: var(--width-buttons);
  margin-right: var(--margin-between);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: 2px solid var(--color-light-navy);
  color: var(--color-light-navy);
  cursor: pointer;
  transition: 0.2s all;
}

.visibleDayButton:before {
  transition: 0.2s all;
}

.visibleDayButtonActive {
  background-color: var(--color-light-navy);
  color: var(--color-white);
}

.disabled {
  position: relative;
  cursor: not-allowed;
  color: var(--color-medium-grey);
  background-color: transparent;
  border-color: var(--color-medium-grey);
  /* Adds outline around text w/ full browser support */
  text-shadow: 0px 1px 0 var(--color-white),
               1px 0px 0 var(--color-white),
               0px -1px 0 var(--color-white),
               -1px 0px 0 var(--color-white);
}

.disabled:before {
  content: '';
  position: absolute;
  height: 54px;
  width: 2px;
  background-color: var(--color-medium-grey);
  transform: rotate(45deg);
  transform-origin: 50% 50%;
  z-index: -1;
}
