@font-face {
  font-family: 'Inter-UI';
  src: url('./fonts/Inter-UI-Bold.woff2') format('woff2'); /* Pretty Modern Browsers */
  font-weight: bolder;
}

@font-face {
  font-family: 'Inter-UI';
  src: url('./fonts/Inter-UI-Italic.woff2') format('woff2'); /* Pretty Modern Browsers */
  font-style: italic;
}

@font-face {
  font-family: 'Inter-UI';
  src: url('./fonts/Inter-UI-Medium.woff2') format('woff2'); /* Pretty Modern Browsers */
  font-weight: bold;
}

@font-face {
  font-family: 'Inter-UI';
  src: url('./fonts/Inter-UI-Regular.woff2') format('woff2'); /* Pretty Modern Browsers */
  font-weight: normal;
}

body {
  font-family: 'Inter-UI', sans-serif;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: 'Inter-UI', sans-serif;
  font-weight: bolder;
}

p {
  font-family: 'Inter-UI', sans-serif;
  margin: 0;
}

:root {
  --font-small: 12px;
  --font-regular: 14px;
  --font-big: 16px;
}
