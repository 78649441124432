.grid {
  display: grid;
  grid-template-columns: auto 80px;
  align-items: center;
}

.controlls {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.delete {
  cursor: pointer;
}

.orderedStatement {
  & > * {
    border-bottom: 1px solid var(--color-grey);
    margin-bottom: var(--margin-small);
  }

  & > *:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  & > div > div:active {
    box-shadow: none;
    border: none;
  }

  & > div > div:hover {
    border: none;
  }
}
