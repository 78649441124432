.fileInput {
  display: inline-block;
  height: var(--height-content);
  margin: var(--margin-between) 0;
  font-size: var(--font-regular);
  text-align: center;
  cursor: pointer;
  border: 2px solid var(--color-light-navy);
  padding: var(--padding-container);
  color: var(--color-light-navy);

  & + input {
    display: none;
  }
}

.disabledInput {
  cursor: not-allowed;
}

.icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: var(--margin-between);
  vertical-align: middle;
  fill: var(--color-light-navy);
}

.filename {
  display: inline;
  padding: 5px;
  font-style: italic;
}
