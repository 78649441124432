.queryStatements {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.queryScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: var(--padding-container);
  *:hover {
    cursor: pointer;
  }
}

.queryWrapper {
  position: relative;
  overflow: hidden;
}

.queryInput {
  margin: var(--margin-between-topBottom);
  padding: 0 40px 0 10px !important;
  flex-shrink: 0;
  cursor: default;
  outline: 0;
}

.clearQueryInput {
	background-color: transparent;
	border: 0;
  outline: 0;
  cursor: default;
	z-index: 1;
	display: block;
	position: absolute;
  right: 6px;
  bottom: 18px;
  height: 24px;
  width: 24px;
  padding: 0;
	color: var(--color-grey);
	font-size: var(--font-big);
}

.clearQueryInput:hover {
  border-radius: 50%;
  border: 1px solid var(--color-grey);
}

.clearQueryInput:active {
  border-radius: 50%;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 4px 30px 0 rgba(0, 0, 0, 0.19);
  border: none;
}
