:root {
  --padding-container: 10px;
  --padding-content: 20px;
  --padding-medium: 15px;

  --margin-small: 5px;
  --margin-between: 10px;
  --margin-around: 20px;
  --margin-between-topBottom: 10px 0;
  --margin-between-rightLeft: 0 10px;

  --height-small: 20px;
  --height-content: 40px;
  --height-workspace: 60px;
  --height-tall: 90px;

  --width-buttons: 40px;
}
