:root {
  --color-white: #FFFFFF;
  --color-grey: #808080;
  --color-black: #000000;
  --color-red: #EB5757; /* opaque red */
  --color-orange: #FFA600;
  --color-yellow: #FFD530;
  --color-green: #27AE60;
  --color-blue: #2D9CDB;

  --color-light-navy: #3B4DDF;
  --color-medium-navy: #2938A7;
  --color-dark-navy: #192383;

  --color-offwhite: #FAFBFC;

  --color-light-grey: #F6F6F7;
  --color-light-medium-grey: #F2F2F2;
  --color-medium-grey: #D0D1E0;
  --color-dark-grey: #4F4F4F;
  --color-bluey-grey: #F2F3F6;

  --color-error: #FF0000; /* red */
  --color-destroy: #D62222; /* deep red */
  --color-warning: #EDA407; /* orange */
  --color-action: #0CC79A; /* green-aqua */

  --opacity-hover: 0.8;
  --opacity-disabled: 0.5;
}
