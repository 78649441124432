$distance-edge: 10px;
$zIndex-tableHeader: 10;

.iconContainer {
  position: fixed;
  right: $distance-edge;
  top: $distance-edge;
  z-index: calc(#{$zIndex-tableHeader} + 1);

  & svg {
    color: var(--color-white);
    width: var(--width-buttons);
    height: var(--width-buttons);
  }
}

.iconContainer:hover {
  cursor: pointer;
}

.reset {
  width: 100%;
  height: 100%;
}
