.input {
  background-color: var(--color-white);
  border-radius: 2px;
  height: var(--height-content);
  width: 100%;
  padding: 0 var(--padding-container);
  font-size: var(--font-regular);
  font-weight: 300;
  color: var(--color-dark-grey);
  transition: all 0.4s;
  border: 1px solid var(--color-medium-grey);

  &:focus {
    outline-width: 0;
  }
}

.input:disabled,
.input:read-only {
  background-color: var(--color-light-grey);
  /* Safari ignores color on disabled inputs */
  -webkit-text-fill-color: var(--color-dark-grey);
  cursor: not-allowed;
}

:global(.inputHasError) .input,
.globalError {
  border-color: var(--color-error);
}
