/**
 * Product Table Custom Headers (New Prouduct + Quick Start)
 * Intent: In order to properly position these buttons within our
 * table, we'll use the fixed position CSS property in order to
 * visually "place" them into place in the table.
 * This is very prone to breaking if widths change.
 */
.buttonContainer {
  height: var(--height-content);
  width: 100%;
}

.buttonContainerQuickStart {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.buttonContainerNewProduct button span {
  padding-right: 16px;
}

.buttonContainer > .buttonHalf {
  flex-grow: 1;
  padding-right: 5px;

  & > button {
    padding: 0 var(--padding-container);
  }
}

/* React Table Custom Cell Rules */
.dateFiltersContainer {
  text-align: left;
  .dayPicker, .dropdown {
    display: inline-block;
  }
  .dayPicker {
    input {
      width: 100%;
      overflow: hidden;
    }
  }

  .dropdown {
    width: 120px !important;
  }
}

.dateIsActive {
  position: relative;
}

.dateIsActive:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--color-white);
}
