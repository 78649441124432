.container {
  width: 250px;
  height: 250px;
}

.svg {
  width: 100%;
  height: 100%;
}

.elevation {
  cursor: pointer;
  transition: 0.2s all;
}

.checkboxWrapper {
  display: none;
}

