.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
}

.noData {
  text-align: center;
  margin-top: 100px;
}

.container input:read-only {
  cursor: pointer;
}

.container input:disabled {
  cursor: not-allowed;
}
