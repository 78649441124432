.combo {
  display: table;
  width: 100%;
  padding: 0;
  line-height: 1;
}

.first,
.last {
  display: table-cell;
  vertical-align: top;
}

.first {
  width: 0;
  padding-right: var(--padding-container);
  line-height: 0;
}

.last {
  width: 100%;
  line-height: 1.6;
}

.last.center {
  width: auto;
}

/* Reverse */
.reverse > .first {
  width: 100%;
  line-height: 1.6;
}

.reverse > .last {
  width: 0;
  white-space: nowrap;
  line-height: 0;
}

/* Vertical alignments - middle */
.middle > * {
  vertical-align: middle;
}

/* Vertical alignments - middle */
.bottom > * {
  vertical-align: bottom;
}
